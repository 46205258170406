import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { getAllergies, deleteAllergy } from '../../actions/allergy';
import AllergiesModal from '../../components/AllergiesModal/AllergiesModal';
import styles from './Allergies.module.css';

const Allergies = (props) => {
  const { 
    getAllergies, 
    allergiesData,
    deleteAllergy,
   } = props;

  const [showModal, setShowModal] = useState(false);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    getAllergies();
  }, []);

  const openModalHandler = () => {
    setInitialValue(null);
    setShowModal(true);
  };

  const editHandler = (allergyData) => {
    setInitialValue(allergyData);
    setShowModal(true);
  };

  const deleteHandler = (id) => {
    const confirmResult = window.confirm('Are you sure?');

    if (confirmResult) {
      deleteAllergy(id);
    }
  };

  return (
    <div className="wrapper">
      <AllergiesModal 
        key={initialValue?.id}
        isOpen={showModal} 
        onClose={() => setShowModal(false)}
        initialValue={initialValue}
      />
      <div className={styles.tools}>
      <h1>
        Allergies
      </h1>
      <div className={styles.buttons}>
        <div className={styles.addDish} onClick={openModalHandler}>
          Add allergy
        </div>
      </div>
      </div>
      <table className={cn(styles.table, 'table')}>
        <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Controls</th>
        </tr>
        </thead>
        <tbody>
        {allergiesData?.map((allergyItem) => (
          <tr key={allergyItem.id}>
            <td>
              {allergyItem.id}
            </td>
            <td>
              {allergyItem.name}
            </td>
            <td className="controls">
              <button className="button green-button" onClick={() => editHandler(allergyItem)}>
                Edit
              </button>
              <button className="button red-button" onClick={() => deleteHandler(allergyItem.id)}>
                Delete
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (store) => ({
  allergiesData: store.allergy.allergiesData,
});

const mapDispatchToProps = {
  getAllergies, 
  deleteAllergy,
};

export default connect(mapStateToProps, mapDispatchToProps)(Allergies);